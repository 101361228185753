import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="bg-green-600 text-white">
      <nav className="container mx-auto px-6 py-3">
        <ul className="flex justify-between items-center">
          <li>
            <Link to="/" className="text-xl font-bold hover:text-green-200">AICI Sports</Link>
          </li>
          
          <li>
            <Link to="/events" className="hover:text-green-200">Events</Link>
          </li>
          <li>
            <Link to="/teams" className="hover:text-green-200">Teams</Link>
          </li>
          <li>
            <Link to="/contact" className="hover:text-green-200">Contact</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
