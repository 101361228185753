import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';

const EventList = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const snapshot = await getDocs(collection(db, 'events'));
        const fetchedEvents = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setEvents(fetchedEvents);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
        setLoading(false);
      }
    };
    fetchEvents();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleShare = async (event) => {
    const eventUrl = `${window.location.origin}/event/${event.id}`;
    if (navigator.share) {
      try {
        await navigator.share({
          title: event.title,
          text: event.description,
          url: eventUrl,
        });
        alert('Event shared successfully!');
      } catch (error) {
        console.error('Error sharing event:', error);
        alert('Failed to share the event. Please try again.');
      }
    } else {
      // Fallback for browsers that do not support the Web Share API
      prompt('Copy and share this link:', eventUrl);
    }
  };

  if (loading) {
    return <p>Loading events...</p>;
  }

  return (
    <div className="min-h-screen bg-gray-100 text-gray-800 p-4 sm:p-6 md:p-8">
      <motion.h1 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-3xl sm:text-4xl md:text-5xl font-bold mb-8 text-center text-blue-800"
      >
        Event Registration
      </motion.h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {events.map(event => (
          <motion.div 
            key={event.id} 
            className="bg-white rounded-lg shadow-lg overflow-hidden"
            whileHover={{ scale: 1.03 }}
            transition={{ duration: 0.3 }}
          >
            <img src={event.image} alt={event.title} className="w-full h-48 object-cover" />
            <div className="p-4 sm:p-6">
              <h2 className="text-xl sm:text-2xl font-bold mb-2 text-blue-800">{event.title}</h2>
              <p className="text-gray-600 mb-4 text-sm sm:text-base">{event.description}</p>
              <p className="mb-1 text-sm"><span className="font-semibold">Date:</span> {formatDate(event.date)}</p>
              <p className="mb-3 text-sm"><span className="font-semibold">Registration Deadline:</span> {formatDate(event.registrationDeadline)}</p>
              <div className="flex justify-between">
                <Link 
                  to={`/event/${event.id}`}
                  className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300 mr-2"
                >
                  Register Now
                </Link>
                <button 
                  onClick={() => handleShare(event)}
                  className="w-full bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 transition duration-300"
                >
                  Share
                </button>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default EventList;
