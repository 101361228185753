import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { motion } from 'framer-motion';

const EventDetail = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventDoc = await getDoc(doc(db, 'events', eventId));
        if (eventDoc.exists()) {
          setEvent({ id: eventDoc.id, ...eventDoc.data() });
        } else {
          console.error("No such event!");
        }
      } catch (error) {
        console.error("Error fetching event:", error);
      }
    };
    fetchEvent();
  }, [eventId]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Filter out empty fields
      const filteredFormData = Object.fromEntries(
        Object.entries(formData).filter(([_, value]) => value !== '')
      );

      // Check if there's any data to submit
      if (Object.keys(filteredFormData).length === 0) {
        throw new Error("No data to submit. Please fill out the form.");
      }

      // Add eventId to the filtered form data
      filteredFormData.eventId = event.id;

      await addDoc(collection(db, 'registrations'), filteredFormData);
      setSuccess(true);
      setFormData({});
    } catch (error) {
      console.error("Error adding document: ", error);
      alert(error.message || "Failed to submit registration. Please try again.");
    }
    setLoading(false);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (!event) {
    return <p>Loading event details...</p>;
  }

  return (
    <div className="min-h-screen bg-gray-100 text-gray-800 p-4 sm:p-6 md:p-8">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-4xl mx-auto"
      >
        <button 
          onClick={() => window.history.back()}
          className="mb-6 bg-gray-200 text-gray-800 py-2 px-4 rounded-md hover:bg-gray-300 transition duration-300"
        >
          ← Back to Events
        </button>
        <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-blue-800">{event.title}</h2>
          <img src={event.image} alt={event.title} className="w-full h-64 object-cover rounded-md mb-4" />
          <p className="text-gray-600 mb-4">{event.description}</p>
          <p className="mb-1"><span className="font-semibold">Date:</span> {formatDate(event.date)}</p>
          <p className="mb-4"><span className="font-semibold">Registration Deadline:</span> {formatDate(event.registrationDeadline)}</p>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2 text-blue-800">Price Tiers</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {event.priceTiers.map((tier, index) => (
                <div key={index} className="bg-gray-100 p-2 rounded-md">
                  <span className="font-semibold">{tier.name}:</span> ${tier.price}
                </div>
              ))}
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-lg p-6">
          {event.questions.map((field, index) => (
            <div key={index} className="mb-4">
              <label htmlFor={field.label} className="block text-gray-700 font-semibold mb-1">{field.label}</label>
              <input
                type={field.type}
                id={field.label}
                name={field.label}
                value={formData[field.label] || ''}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
          ))}
          <div className="mb-4">
            <label htmlFor="priceTier" className="block text-gray-700 font-semibold mb-1">Select Price Tier</label>
            <select
              id="priceTier"
              name="priceTier"
              value={formData.priceTier || ''}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            >
              <option value="">Select a price tier...</option>
              {event.priceTiers.map((tier, index) => (
                <option key={index} value={tier.name}>{tier.name} - ${tier.price}</option>
              ))}
            </select>
          </div>
          <motion.button
            type="submit"
            className={`w-full bg-blue-600 text-white py-3 px-4 rounded-md text-lg font-semibold hover:bg-blue-700 transition duration-300 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loading}
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.98 }}
          >
            {loading ? 'Registering...' : 'Complete Registration'}
          </motion.button>
          {success && (
            <motion.p 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="mt-4 text-green-600 text-center font-bold"
            >
              Registration successful! We'll be in touch soon.
            </motion.p>
          )}
        </form>
      </motion.div>
    </div>
  );
};

export default EventDetail;