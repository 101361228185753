import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';

const Teams = () => {
  const [teams, setTeams] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeams = async () => {
      const teamsSnapshot = await getDocs(collection(db, 'teams'));
      setTeams(teamsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchTeams();
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-4xl font-bold text-mosque-green">Tournament Teams</h1>
          <button 
            onClick={() => navigate('/coach-auth')} 
            className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
          >
            Coach Login/Sign Up
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {teams.map((team) => (
            <div key={team.id} className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition duration-300">
              <h2 className="text-2xl font-semibold text-mosque-green mb-2">{team.name}</h2>
              <p className="text-gray-600 mb-2">Sport: {team.sport}</p>
              <p className="text-gray-600">Members: {team.members.length}</p>
              <div>
                {team.members.map((member) => (
                  <div key={member.id} className="bg-gray-100 p-2 mt-2 rounded">
                    <p>Name: {member.name}</p>
                    <p>Description: {member.description}</p>
                    {member.image && <img src={member.image} alt={member.name} className="w-20 h-20 rounded" />}
                  </div>
                ))}
              </div>
              <button 
                onClick={() => navigate(`/coach?teamId=${team.id}`)} 
                className="bg-green-600 text-white px-4 py-2 rounded-lg mt-4 hover:bg-green-700"
              >
                Manage Team
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Teams;
