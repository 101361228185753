import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { signOut } from 'firebase/auth';
import { db, auth, storage } from '../firebase';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const [registrations, setRegistrations] = useState([]);
  const [update, setUpdate] = useState({ content: '', image: null, date: '' });
  const [updates, setUpdates] = useState([]);
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    title: '',
    description: '',
    date: '',
    registrationDeadline: '',
    image: null,
    priceTiers: [{ name: '', price: '' }],
    questions: []
  });
  const [newQuestion, setNewQuestion] = useState({ label: '', type: 'text', options: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [teams, setTeams] = useState([]);
  const [newTeam, setNewTeam] = useState({ name: '', sport: '', members: [], coaches: [] });
  const [coaches, setCoaches] = useState([]);
  const [newCoach, setNewCoach] = useState({ name: '', email: '' });
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const registrationsSnapshot = await getDocs(collection(db, 'registrations'));
      setRegistrations(registrationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      const eventsSnapshot = await getDocs(collection(db, 'events'));
      setEvents(eventsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      const teamsSnapshot = await getDocs(collection(db, 'teams'));
      setTeams(teamsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  
      const coachesSnapshot = await getDocs(collection(db, 'coaches'));
      setCoaches(coachesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    
      const updatesSnapshot = await getDocs(collection(db, 'updates'));
      setUpdates(updatesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (err) {
      console.error("Error fetching data: ", err);
      setError("Failed to load data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image') {
      setUpdate({ ...update, image: files[0] });
    } else {
      setUpdate({ ...update, [name]: value });
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      let imageUrl = null;
      if (update.image) {
        const fileRef = ref(storage, `updates/${update.image.name}`);
        await uploadBytes(fileRef, update.image);
        imageUrl = await getDownloadURL(fileRef);
      }

      await addDoc(collection(db, 'updates'), {
        content: update.content,
        image: imageUrl,
        date: update.date,
        timestamp: new Date()
      });

      setUpdate({ content: '', image: null, date: '' });
      fetchData();
      alert("Update posted successfully!");
    } catch (err) {
      console.error("Error posting update: ", err);
      alert("Failed to post update. Please try again.");
    }
  };

  const handleUpdateDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this update?")) {
      try {
        await deleteDoc(doc(db, 'updates', id));
        fetchData();
        alert("Update deleted successfully!");
      } catch (err) {
        console.error("Error deleting update: ", err);
        alert("Failed to delete update. Please try again.");
      }
    }
  };

  const handleTeamChange = (e) => {
    const { name, value } = e.target;
    setNewTeam({ ...newTeam, [name]: value });
  };

  const handleAddTeam = async (e) => {
    e.preventDefault();
    await addDoc(collection(db, 'teams'), newTeam);
    setNewTeam({ name: '', sport: '', members: [], coaches: [] });
    fetchData();
  };

  const handleDeleteTeam = async (id) => {
    await deleteDoc(doc(db, 'teams', id));
    fetchData();
  };

  const handleCoachChange = (e) => {
    const { name, value } = e.target;
    setNewCoach({ ...newCoach, [name]: value });
  };

  const handleAddCoach = async (e) => {
    e.preventDefault();
    await addDoc(collection(db, 'coaches'), newCoach);
    setNewCoach({ name: '', email: '' });
    fetchData();
  };

  const handleDeleteCoach = async (id) => {
    await deleteDoc(doc(db, 'coaches', id));
    fetchData();
  };

  const handleEventChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image') {
      setNewEvent({ ...newEvent, image: files[0] });
    } else {
      setNewEvent({ ...newEvent, [name]: value });
    }
  };

  const handlePriceTierChange = (index, field, value) => {
    const updatedPriceTiers = newEvent.priceTiers.map((tier, i) => 
      i === index ? { ...tier, [field]: value } : tier
    );
    setNewEvent({ ...newEvent, priceTiers: updatedPriceTiers });
  };

  const addPriceTier = () => {
    setNewEvent({ 
      ...newEvent, 
      priceTiers: [...newEvent.priceTiers, { name: '', price: '' }] 
    });
  };

  const removePriceTier = (index) => {
    setNewEvent({
      ...newEvent,
      priceTiers: newEvent.priceTiers.filter((_, i) => i !== index)
    });
  };

  const handleQuestionChange = (e) => {
    const { name, value } = e.target;
    setNewQuestion({ ...newQuestion, [name]: value });
  };

  const addQuestion = () => {
    if (newQuestion.type === 'select') {
      newQuestion.options = newQuestion.options.split(',').map(option => option.trim());
    } else {
      delete newQuestion.options;
    }
    setNewEvent({ ...newEvent, questions: [...newEvent.questions, newQuestion] });
    setNewQuestion({ label: '', type: 'text', options: '' });
  };

  const removeQuestion = (index) => {
    const updatedQuestions = newEvent.questions.filter((_, i) => i !== index);
    setNewEvent({ ...newEvent, questions: updatedQuestions });
  };

  const uploadImage = async (image) => {
    if (!image) return null;
    const fileRef = ref(storage, `images/${image.name}`);
    await uploadBytes(fileRef, image);
    const downloadURL = await getDownloadURL(fileRef);
    console.log("Image uploaded, URL:", downloadURL);
    return downloadURL;
  };

  const handleEventSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const imageUrl = await uploadImage(newEvent.image);
      
      const eventData = {
        ...newEvent,
        image: imageUrl,
      };

      await addDoc(collection(db, 'events'), eventData);
      setNewEvent({
        title: '',
        description: '',
        date: '',
        registrationDeadline: '',
        image: null,
        priceTiers: [{ name: '', price: '' }],
        questions: []
      });
      fetchData();
      alert("Event added successfully!");
    } catch (err) {
      console.error("Error adding event: ", err);
      alert("Failed to add event. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleEventDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      try {
        await deleteDoc(doc(db, 'events', id));
        fetchData();
        alert("Event deleted successfully!");
      } catch (err) {
        console.error("Error deleting event: ", err);
        alert("Failed to delete event. Please try again.");
      }
    }
  };

  const handleRegistrationDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this registration?")) {
      try {
        await deleteDoc(doc(db, 'registrations', id));
        fetchData();
        alert("Registration deleted successfully!");
      } catch (err) {
        console.error("Error deleting registration: ", err);
        alert("Failed to delete registration. Please try again.");
      }
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (err) {
      console.error("Error signing out: ", err);
      alert("Failed to sign out. Please try again.");
    }
  };

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-600">{error}</div>;
  }

  return (
    <div className="container mx-auto px-6 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Admin Dashboard</h1>
        <button 
          onClick={handleSignOut}
          className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
        >
          Sign Out
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div>
          <h2 className="text-2xl font-semibold mb-4">Registrations</h2>
          <div className="bg-white rounded-lg shadow-md p-6 max-h-96 overflow-y-auto">
            {registrations.length > 0 ? (
              registrations.map(reg => (
                <div key={reg.id} className="mb-4 p-4 border rounded">
                  <h3 className="font-bold">{reg.Name || 'Unknown'}</h3>
                  {Object.entries(reg).map(([key, value]) => (
                    key !== 'id' && <p key={key}><strong>{key}:</strong> {value}</p>
                  ))}
                  <button 
                    onClick={() => handleRegistrationDelete(reg.id)}
                    className="mt-2 bg-red-600 text-white px-2 py-1 rounded hover:bg-red-700"
                  >
                    Delete
                  </button>
                </div>
              ))
            ) : (
              <p>No registrations yet.</p>
            )}
          </div>
        </div>
        <div>
          <h2 className="text-2xl font-semibold mb-4">Post Update</h2>
          <form onSubmit={handleUpdateSubmit} className="bg-white rounded-lg shadow-md p-6">
            <textarea
              name="content"
              value={update.content}
              onChange={handleUpdateChange}
              className="w-full p-2 border rounded mb-4"
              rows="4"
              placeholder="Enter update..."
              required
            ></textarea>
            <input
              type="date"
              name="date"
              value={update.date}
              onChange={handleUpdateChange}
              className="w-full p-2 border rounded mb-4"
              required
            />
            <input
              type="file"
              name="image"
              onChange={handleUpdateChange}
              accept="image/*"
              className="w-full p-2 border rounded mb-4"
            />
            <button type="submit" className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700">
              Post Update
            </button>
          </form>
          
          <h3 className="text-xl font-semibold mt-6 mb-4">Existing Updates</h3>
          <div className="bg-white rounded-lg shadow-md p-6 max-h-96 overflow-y-auto">
            {updates.map(update => (
              <div key={update.id} className="mb-4 p-4 border rounded">
                <p>{update.content}</p>
                <p><strong>Date:</strong> {update.date}</p>
                {update.image && <img src={update.image} alt="Update" className="mt-2 max-w-full h-auto" />}
                <button 
                  onClick={() => handleUpdateDelete(update.id)}
                  className="mt-2 bg-red-600 text-white px-2 py-1 rounded hover:bg-red-700"
                >
                  Delete
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Create New Event</h2>
        <form onSubmit={handleEventSubmit} className="bg-white rounded-lg shadow-md p-6">
          <input
            type="text"
            name="title"
            value={newEvent.title}
            onChange={handleEventChange}
            placeholder="Event Title"
            className="w-full p-2 border rounded mb-2"
            required
          />
          <textarea
            name="description"
            value={newEvent.description}
            onChange={handleEventChange}
            placeholder="Event Description"
            className="w-full p-2 border rounded mb-2"
            required
          ></textarea>
          <input
            type="date"
            name="date"
            value={newEvent.date}
            onChange={handleEventChange}
            className="w-full p-2 border rounded mb-2"
            required
          />
          <input
            type="date"
            name="registrationDeadline"
            value={newEvent.registrationDeadline}
            onChange={handleEventChange}
            className="w-full p-2 border rounded mb-2"
            required
          />
          <input
            type="file"
            name="image"
            onChange={handleEventChange}
            accept="image/*"
            className="w-full p-2 border rounded mb-2"
          />
          
          <h3 className="text-xl font-semibold mb-2">Price Tiers</h3>
          {newEvent.priceTiers.map((tier, index) => (
            <div key={index} className="flex mb-2">
              <input
                type="text"
                value={tier.name}
                onChange={(e) => handlePriceTierChange(index, 'name', e.target.value)}
                placeholder="Tier Name"
                className="w-1/2 p-2 border rounded-l"
                required
              />
              <input
                type="number"
                value={tier.price}
                onChange={(e) => handlePriceTierChange(index, 'price', e.target.value)}
                placeholder="Price"
                className="w-1/2 p-2 border rounded-r"
                required
              />
              <button
                type="button"
                onClick={() => removePriceTier(index)}
                className="ml-2 bg-red-600 text-white px-2 py-1 rounded hover:bg-red-700"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addPriceTier}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 mb-4"
          >
            Add Price Tier
          </button>
          
          <h3 className="text-xl font-semibold mb-2">Event Questions</h3>
          {newEvent.questions.map((question, index) => (
            <div key={index} className="mb-2 flex justify-between items-center">
              <span>{question.label} ({question.type})</span>
              <button 
                type="button"
                onClick={() => removeQuestion(index)}
                className="bg-red-600 text-white px-2 py-1 rounded hover:bg-red-700"
              >
                Remove
              </button>
            </div>
          ))}
          
          <div className="mb-2">
            <input
              type="text"
              name="label"
              value={newQuestion.label}
              onChange={handleQuestionChange}
              placeholder="Question Label"
              className="w-full p-2 border rounded mb-2"
              required
            />
            <select
            jsxCopy              name="type"
            value={newQuestion.type}
            onChange={handleQuestionChange}
            className="w-full p-2 border rounded mb-2"
            required
          >
            <option value="text">Text</option>
            <option value="email">Email</option>
            <option value="number">Number</option>
            <option value="date">Date</option>
            <option value="select">Select (Dropdown)</option>
          </select>
          {newQuestion.type === 'select' && (
            <input
              type="text"
              name="options"
              value={newQuestion.options}
              onChange={handleQuestionChange}
              placeholder="Options (comma-separated)"
              className="w-full p-2 border rounded mb-2"
            />
          )}
          <button 
            type="button"
            onClick={addQuestion}
            className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
          >
            Add Question
          </button>
        </div>
        
        <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
          Create Event
        </button>
      </form>
    </div>

    <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Add New Team</h2>
        <form onSubmit={handleAddTeam} className="bg-white rounded-lg shadow-md p-6">
          <input
            type="text"
            name="name"
            value={newTeam.name}
            onChange={handleTeamChange}
            placeholder="Team Name"
            className="w-full p-2 border rounded mb-2"
            required
          />
          <input
            type="text"
            name="sport"
            value={newTeam.sport}
            onChange={handleTeamChange}
            placeholder="Sport"
            className="w-full p-2 border rounded mb-2"
            required
          />
          <select
            name="coaches"
            multiple
            value={newTeam.coaches}
            onChange={(e) => setNewTeam({ ...newTeam, coaches: Array.from(e.target.selectedOptions, option => option.value) })}
            className="w-full p-2 border rounded mb-4"
            required
          >
            {coaches.map(coach => (
              <option key={coach.id} value={coach.email}>{coach.name}</option>
            ))}
          </select>
          <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
            Add Team
          </button>
        </form>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Teams</h2>
        {teams.map(team => (
          <div key={team.id} className="bg-white rounded-lg shadow-md p-6 mb-4">
            <h3 className="text-xl font-bold">{team.name}</h3>
            <p>Sport: {team.sport}</p>
            <p>Coaches: {team.coaches.join(', ')}</p>
            <button
              onClick={() => handleDeleteTeam(team.id)}
              className="mt-2 bg-red-600 text-white px-2 py-1 rounded hover:bg-red-700"
            >
              Delete Team
            </button>
          </div>
        ))}
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Add New Coach</h2>
        <form onSubmit={handleAddCoach} className="bg-white rounded-lg shadow-md p-6">
          <input
            type="text"
            name="name"
            value={newCoach.name}
            onChange={handleCoachChange}
            placeholder="Coach Name"
            className="w-full p-2 border rounded mb-2"
            required
          />
          <input
            type="email"
            name="email"
            value={newCoach.email}
            onChange={handleCoachChange}
            placeholder="Coach Email"
            className="w-full p-2 border rounded mb-2"
            required
          />
          <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
            Add Coach
          </button>
        </form>
      </div>

      <div>
        <h2 className="text-2xl font-semibold mb-4">Coaches</h2>
        {coaches.map(coach => (
          <div key={coach.id} className="bg-white rounded-lg shadow-md p-6 mb-4">
            <h3 className="text-xl font-bold">{coach.name}</h3>
            <p>Email: {coach.email}</p>
            <button
              onClick={() => handleDeleteCoach(coach.id)}
              className="mt-2 bg-red-600 text-white px-2 py-1 rounded hover:bg-red-700"
            >
              Delete Coach
            </button>
          </div>
        ))}
   </div>

    <div>
      <h2 className="text-2xl font-semibold mb-4">Existing Events</h2>
      <div className="bg-white rounded-lg shadow-md p-6">
        {events.map(event => (
          <div key={event.id} className="mb-4 p-4 border rounded">
            <h3 className="font-bold">{event.title}</h3>
            <p><strong>Date:</strong> {event.date}</p>
            <p><strong>Registration Deadline:</strong> {event.registrationDeadline}</p>
            <p>{event.description}</p>
            {event.image && <img src={event.image} alt={event.title} className="mt-2 max-w-full h-auto" />}
            <h4 className="font-semibold mt-2">Price Tiers:</h4>
            <ul>
              {event.priceTiers.map((tier, index) => (
                <li key={index}>{tier.name}: ${tier.price}</li>
              ))}
            </ul>
            <h4 className="font-semibold mt-2">Questions:</h4>
            <ul>
              {event.questions.map((question, index) => (
                <li key={index}>{question.label} ({question.type})</li>
              ))}
            </ul>
            <button 
              onClick={() => handleEventDelete(event.id)}
              className="mt-2 bg-red-600 text-white px-2 py-1 rounded hover:bg-red-700"
            >
              Delete Event
            </button>
          </div>
        ))}
      </div>
    </div>
  </div>
);
};

export default AdminDashboard;
