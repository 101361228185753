import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyCBF06BfC1m3WhpeC5FNoj8vztosJF3D7g",
    authDomain: "aici-473c7.firebaseapp.com",
    projectId: "aici-473c7",
    storageBucket: "aici-473c7.appspot.com",
    messagingSenderId: "278490591756",
    appId: "1:278490591756:web:eeca20eef9b8bfe2957429"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const storage = getStorage(app);

export { db, auth, googleProvider, storage };