import React from 'react';
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider } from '../firebase';

const Login = () => {
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate('/admin');
    } catch (error) {
      console.error('Error signing in with Google: ', error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg">
        <h3 className="text-2xl font-bold text-center">Login to Admin Dashboard</h3>
        <div className="mt-4">
          <button
            onClick={handleGoogleSignIn}
            className="px-4 py-2 mt-6 font-bold text-white bg-mosque-green rounded-lg hover:bg-green-700"
          >
            Sign in with Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;