import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-mosque-green text-white">
      <div className="max-w-6xl mx-auto px-4 py-12">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Logo and Tagline */}
          <div className="mb-6 md:mb-0 text-center md:text-left">
            <h2 className="text-2xl font-bold mb-2">Alhuda Foundation</h2>
            <p className="text-sm text-gray-300">Uniting community through sport and faith</p>
          </div>

          {/* Quick Links */}
          <nav className="mb-3 md:mb-0">
            <ul className="flex flex-wrap justify-center space-x-6">
              <FooterLink to="/">Home</FooterLink>
              <FooterLink to="/events">Events</FooterLink>
              <FooterLink to="/contact">Contact</FooterLink>
            </ul>
          </nav>

          {/* Social Icons */}
          <div className="flex space-x-4">
            <SocialIcon href="https://facebook.com" Icon={FaFacebookF} />
            <SocialIcon href="https://twitter.com" Icon={FaTwitter} />
            <SocialIcon href="https://instagram.com" Icon={FaInstagram} />
            <SocialIcon href="mailto:info@alhudafoundation.org" Icon={FaEnvelope} />
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-4 pt-4 border-t border-gray-700 text-center text-sm text-gray-300">
          <p>&copy; {new Date().getFullYear()} Alhuda Foundation. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

const FooterLink = ({ to, children }) => (
  <li>
    <Link to={to} className="text-gray-300 hover:text-mosque-gold transition duration-300">
      {children}
    </Link>
  </li>
);

const SocialIcon = ({ href, Icon }) => (
  <a
    href={href}
    className="text-white hover:text-mosque-gold transition duration-300"
    target="_blank"
    rel="noopener noreferrer"
  >
    <Icon className="w-5 h-5" />
  </a>
);

export default Footer;
