import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { db } from '../firebase';

const Home = () => {
  const [latestEvent, setLatestEvent] = useState(null);
  const [updates, setUpdates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch latest event
        const eventsQuery = query(collection(db, 'events'), orderBy('date', 'desc'), limit(1));
        const eventSnapshot = await getDocs(eventsQuery);
        if (!eventSnapshot.empty) {
          setLatestEvent({ id: eventSnapshot.docs[0].id, ...eventSnapshot.docs[0].data() });
        }

        // Fetch latest updates
        const updatesQuery = query(collection(db, 'updates'), orderBy('timestamp', 'desc'), limit(3));
        const updatesSnapshot = await getDocs(updatesQuery);
        setUpdates(updatesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  return (
    <div className="bg-gradient-to-b from-mosque-green to-green-800 text-white">
      {/* Hero Section */}
      <div className="container mx-auto px-6 py-16">
        <div className="text-center">
          <h1 className="text-5xl font-bold mb-4">Alhuda Foundation Sports Tournament</h1>
          <p className="text-xl mb-8">Uniting our community through the spirit of sport and faith</p>
          {latestEvent && (
            <Link 
              to={`/event/${latestEvent.id}`}
              className="bg-mosque-gold text-mosque-green font-bold py-3 px-8 rounded-full hover:bg-yellow-400 transition duration-300"
            >
              Register for {latestEvent.title}
            </Link>
          )}
        </div>
      </div>

      {/* Latest Event Section */}
      {latestEvent && (
        <div className="bg-white text-gray-800 py-16">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-center mb-12">Upcoming Event</h2>
            <div className="bg-gray-100 rounded-lg p-6 flex flex-col md:flex-row items-center">
              <img src={latestEvent.image} alt={latestEvent.title} className="w-full md:w-1/3 rounded-lg mb-6 md:mb-0 md:mr-6" />
              <div>
                <h3 className="text-2xl font-bold mb-2">{latestEvent.title}</h3>
                <p className="mb-4">{latestEvent.description}</p>
                <p className="mb-2"><strong>Date:</strong> {new Date(latestEvent.date).toLocaleDateString()}</p>
                <p className="mb-4"><strong>Registration Deadline:</strong> {new Date(latestEvent.registrationDeadline).toLocaleDateString()}</p>
                <Link 
                  to={`/event/${latestEvent.id}`}
                  className="bg-mosque-green text-white font-bold py-2 px-4 rounded hover:bg-green-700 transition duration-300"
                >
                  View Details & Register
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Features Section */}
      <div className="bg-white text-gray-800 py-16">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-12">Tournament Highlights</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard 
              title="Multiple Sports"
              description="Compete in basketball, soccer, and volleyball tournaments"
              icon="🏀"
            />
            <FeatureCard 
              title="All Ages Welcome"
              description="Categories for youth, adults, and seniors"
              icon="👪"
            />
            <FeatureCard 
              title="Community Building"
              description="Foster brotherhood and sisterhood through friendly competition"
              icon="🤝"
            />
          </div>
        </div>
      </div>

      {/* Latest Updates Section */}
      <div className="container mx-auto px-6 py-16">
        <h2 className="text-3xl font-bold mb-8">Latest Updates</h2>
        <div className="bg-white text-gray-800 rounded-lg shadow-xl p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {updates.map(update => (
              <UpdateItem 
                key={update.id}
                date={new Date(update.date).toLocaleDateString()}
                title={update.content.substring(0, 50) + '...'}
                content={update.content}
                image={update.image}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="bg-mosque-gold text-mosque-green py-16">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to Join the Tournament?</h2>
          <p className="text-xl mb-8">Don't miss out on this exciting community event!</p>
          <Link 
            to="/events" 
            className="bg-mosque-green text-white font-bold py-3 px-8 rounded-full hover:bg-green-700 transition duration-300 inline-flex items-center"
          >
            View All Events
            <ChevronRightIcon className="w-5 h-5 ml-2" />
          </Link>
        </div>
      </div>
    </div>
  );
};

const FeatureCard = ({ title, description, icon }) => (
  <div className="bg-gray-100 rounded-lg p-6 text-center hover:shadow-lg transition duration-300">
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

const UpdateItem = ({ date, title, content, image }) => (
  <div className="bg-gray-100 rounded-lg p-4 shadow-md">
    <div className="text-sm text-gray-500 mb-1">{date}</div>
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    <p className="text-sm mb-2">{content.substring(0, 100)}...</p>
    {image && (
      <img 
        src={image} 
        alt="Update" 
        className="w-full h-32 object-cover rounded" 
      />
    )}
  </div>
);

export default Home;