import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, updateDoc, doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase'; // Ensure 'db' is imported from your Firebase configuration
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

const CoachDashboard = () => {
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [teamName, setTeamName] = useState('');
  const [teamSport, setTeamSport] = useState('');
  const [playerName, setPlayerName] = useState('');
  const [playerDescription, setPlayerDescription] = useState('');
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    const fetchTeams = async () => {
      const teamsSnapshot = await getDocs(collection(db, 'teams'));
      const userTeams = teamsSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(team => team.coaches && team.coaches.includes(user.email)); // Ensure team.coaches is defined and includes user.email
      setTeams(userTeams);
    };

    fetchTeams();
  }, [user, navigate]);

  const handleAddPlayer = async (e) => {
    e.preventDefault();
    if (selectedTeam && playerName && playerDescription) {
      const teamDoc = doc(db, 'teams', selectedTeam);
      const teamSnapshot = await getDoc(teamDoc);
      const teamData = teamSnapshot.data();
      const newPlayer = {
        id: new Date().getTime().toString(), // Generate a unique id for the player
        name: playerName,
        description: playerDescription,
        paid: false,
      };
      await updateDoc(teamDoc, {
        members: [...teamData.members, newPlayer],
      });
      setPlayerName('');
      setPlayerDescription('');
      alert('Player added successfully!');
    }
  };

  const handleAddTeam = async (e) => {
    e.preventDefault();
    if (teamName && teamSport) {
      const newTeam = {
        name: teamName,
        sport: teamSport,
        members: [],
        coaches: [user.email],
      };
      await addDoc(collection(db, 'teams'), newTeam);
      setTeamName('');
      setTeamSport('');
      alert('Team added successfully!');
    }
  };

  return (
    <div className="container mx-auto px-6 py-8">
      <h1 className="text-3xl font-bold mb-8">Coach Dashboard</h1>

      <form onSubmit={handleAddTeam} className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Add Team</h2>
        <input
          type="text"
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          placeholder="Team Name"
          className="w-full p-2 border rounded mb-4"
          required
        />
        <input
          type="text"
          value={teamSport}
          onChange={(e) => setTeamSport(e.target.value)}
          placeholder="Sport"
          className="w-full p-2 border rounded mb-4"
          required
        />
        <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
          Add Team
        </button>
      </form>

      <form onSubmit={handleAddPlayer} className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Add Player</h2>
        <select
          value={selectedTeam}
          onChange={(e) => setSelectedTeam(e.target.value)}
          className="w-full p-2 border rounded mb-4"
          required
        >
          <option value="">Select Team</option>
          {teams.map((team) => (
            <option key={team.id} value={team.id}>{team.name}</option>
          ))}
        </select>
        <input
          type="text"
          value={playerName}
          onChange={(e) => setPlayerName(e.target.value)}
          placeholder="Player Name"
          className="w-full p-2 border rounded mb-4"
          required
        />
        <textarea
          value={playerDescription}
          onChange={(e) => setPlayerDescription(e.target.value)}
          placeholder="Player Description"
          className="w-full p-2 border rounded mb-4"
          required
        ></textarea>
        <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
          Add Player
        </button>
      </form>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {teams.map(team => (
          <div key={team.id} className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-semibold mb-2">{team.name}</h2>
            <p>Sport: {team.sport}</p>
            <h3 className="text-xl font-semibold mt-4">Members</h3>
            <ul>
              {team.members && team.members.map(member => (
                <li key={member.id} className="mt-2">
                  <p>Name: {member.name}</p>
                  <p>Description: {member.description}</p>
                  <p>Paid: {member.paid ? 'Yes' : 'No'}</p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoachDashboard;
