import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const subject = encodeURIComponent("Contact from Alhuda Foundation Sports Tournament");
    const body = encodeURIComponent(`Name: ${formData.name}\nEmail: ${formData.email}\n\nMessage: ${formData.message}`);
    window.location.href = `mailto:info@alhudafoundation.org?subject=${subject}&body=${body}`;
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-mosque-green mb-8">Contact Us</h1>
        <div className="bg-white rounded-lg shadow-md p-6 max-w-2xl mx-auto">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-mosque-green"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Your Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-mosque-green"
                required
              />
            </div>
            <div className="mb-6">
              <label htmlFor="message" className="block text-gray-700 font-bold mb-2">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="4"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-mosque-green"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="bg-mosque-green text-white font-bold py-2 px-4 rounded-lg hover:bg-green-700 transition duration-300"
            >
              Open Email Client
            </button>
          </form>
        </div>
        <div className="mt-8 text-center">
          <h2 className="text-2xl font-bold text-mosque-green mb-4">Alhuda Foundation</h2>
          <p>12201 Lantern Rd, Fishers, IN 46038</p>
          <p>Phone: (317) 570-9736</p>
          <p>Email: info@alhudafoundation.org</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;